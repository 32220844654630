.footer{
  font-weight: 500;

  .social-links{
    list-style: none;
    margin: 0;
    padding: 0;

    li{
      margin-bottom: 15px;
      display: block;
      a{
        display: inline-block;
        color: $color-primary;
        opacity: 1;
        i{
          width: 25px;
          line-height: 25px;
          text-align: center;
          background-color: $color-white;
          border-radius: 50%;
          font-size: 1.2em;
        }
      }
    }
  }

  .logo-footer{
    margin-bottom: 15px;
    display: inline-block;
  }

  .paragraph{
    opacity: 0.75;
    font-weight: 500;
  }

  a{
    text-decoration: none;
    opacity: 0.75;

    &:hover{
      opacity: 1;
      color: $color-white;
    }
  }

  .footer-title{
    font-size: 1.4em;
    color: $color-white;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 15px;
  }

  .button{
    background-color: $color-white;
    color: $color-primary;
    opacity: 1;

    &:hover{
      color: $color-primary;
    }
  }
}
