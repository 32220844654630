.custom-modal.user-dialogs{
  .modal-dialog{
    padding: 25px;
    max-height: 525px;
    overflow: auto;
    top: 20px;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}
