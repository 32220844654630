// Variables - Colors, Font Family, etc.
@import "base/styles/variables";

// Modals
@import "pages/sections/modals/dialog-alert-modal";


// Pages
@import "pages/home";
@import "pages/nuestras-obras";

// Sections
@import "pages/sections/footer";
@import "pages/sections/clientes";
@import "pages/sections/contacto";
@import "pages/sections/section-obras-home";

// Cards
@import "cards/obra-card";

// Modals
@import "modals/modal-obra";

// Import Responsive
@import "responsive/responsive";

#fp-nav ul li a{
  span{
    background-color: transparent;
    width: 10px !important;
    height: 10px !important;
    margin: 0 !important;
    border: solid 1px $color-secondary;
  }

  &.active, &:hover, &.active:hover{
    span{
      background-color: $color-secondary;
      margin: 0;
    }
  }
}

#fp-nav ul li .fp-tooltip{
  background-color: $color-secondary;
  padding: 10px;
}
