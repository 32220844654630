.nuestras-obras-page-class{

    .nuestras-obras-banner{
        .container{
            max-width: 1280px;
            .title{
                border-bottom: solid 4px #4297be;
                max-width: 250px;
            }
        }
    }

    .nuestras-obras-cards{
        .container{
            max-width: 1280px;
            .obras-list{
                .card-box{
                    padding-right: 30px;
                }
            }
        }
    }

}
