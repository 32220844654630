.clientes-section{
  height: 100%;

  .container{
    max-width: 1280px;
    position: relative;
  }
    .contacto-button{
      position: absolute;
      bottom: 50px;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);

        i{
            background-color: $color-primary;
            color: $color-white;
            display: inline-block;
            width: 50px;
            line-height: 50px;
            text-align: center;
            border-radius: 50px;
            font-size: 1.6em;
        }
        h3{
            font-family: $font-family-secondary;
            color: $color-primary;
            line-height: 50px;
            display: inline-block;
            margin-left: 15px;
        }

        &:hover{
            text-decoration: none;
            transition: 0.25s;
            i{
                background-color: darken($color-primary, 8%);
            }
            h3{
                color: darken($color-primary, 8%);
            }
        }
    }
    .owl-carousel{
      .item{
        height: 200px;
        background-color: $color-white;
          img{
            width: auto;
            height: auto;
            max-height: 100%;
            max-width: 180px;
            margin: 0 auto;
            opacity: 1;
            // -webkit-filter: grayscale(1);
            // -o-filter: grayscale(1);
            // filter: grayscale(1);

            &:hover{
              opacity: 1;
              // -webkit-filter: grayscale(0);
              // -o-filter: grayscale(0);
              // filter: grayscale(0);
            }
          }
      }

    }
    .nav-button{
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-25%);
      -ms-transform: translateY(-25%);
      transform: translateY(-25%);
      font-size: 4em;
      color: $color-gray-primary;
      z-index: 20;
      &.prev{
        left: 0px;
      }
      &.next{
        right: 0px;
      }
      &:hover{
        color: $color-primary;
        text-decoration: none;
      }
      &:focus{
        color: $color-primary;
        text-decoration: none;
      }
    }
}
