.contacto{
     .form-sidebar{
        vertical-align: middle;
        padding: 40px;
        .contact-info{
            .info{
                margin-right: 40px;
            }
        }
     }
}
