@media all and (max-width: 480px){

// Base
@import "480px/base/footer";

// Modals
@import "480px/modals/modal-obra";

// Pages
@import "480px/pages/sections/nosotros";
@import "480px/pages/sections/contacto";

}
