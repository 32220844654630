footer.main-footer{
  .section-short{
    padding-top: 10px;
    padding-bottom: 0;
  }
  p{
    margin: 0;
  }

  .link-janaq{
    display: block;
  }

  .footer-content{
    &.part2{
      .redes-sociales{
          padding-left: 26px;
          float: right;
          display: block;
      }
      .signature{
          float: left;
          display: block;
      }
    }

  }

}
