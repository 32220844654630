.dialog-alert-modal .modal-dialog {
  background-color: transparent;
}

.dialog-alert-modal .modal-content {
  background-color: transparent;
}

.dialog-alert-modal .modal-content .header-dialog-modal h5 {
  margin-bottom: 0;
  color: #fff;
  font-weight: bold;
  font-family: "Klavika";
}

.dialog-alert-modal .modal-content .content-dialog-modal p {
  margin-bottom: 0;
  color: #8C8C8C;
}

.dialog-alert-modal .modal-content .footer-dialog-modal {
  border-top: solid 1px #EDEDED;
  padding-top: 10px;
  padding-bottom: 2px;
}

.dialog-alert-modal .modal-content .footer-dialog-modal .button {
  margin-left: 8px;
}

.home-page-class body {
  padding-top: 0;
}

.home-page-class .nosotros-section {
  height: 100vh;
  min-height: 650px;
}

.home-page-class .nosotros-section .banner-content .title {
  margin-top: 280px;
  margin-bottom: 40px;
}

.home-page-class .obras-section {
  height: 100vh;
  min-height: 650px;
}

.home-page-class .obras-section .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 0;
  background-color: rgba(34, 72, 120, 0.7);
  /*dim the background*/
}

.home-page-class .obras-section .banner-content .title {
  margin-top: 225px;
  margin-bottom: 40px;
}

.home-page-class .select2-container--default .select2-search--dropdown {
  display: none;
}

.down-anchor-link {
  position: absolute;
  display: inline-block;
  bottom: 50px;
  font-size: 3.6em;
  left: 50%;
  margin-left: -20px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  background-color: #fff;
  color: #224878;
}

.down-anchor-link:hover, .down-anchor-link:focus {
  color: #224878;
  background-color: #fff;
  text-decoration: none;
}

@keyframes floatMove {
  0% {
    -webkit-transform: translateY(-15%);
    -ms-transform: translateY(-15%);
    transform: translateY(-15%);
  }
  50% {
    -webkit-transform: translateY(15%);
    -ms-transform: translateY(15%);
    transform: translateY(15%);
  }
  100% {
    -webkit-transform: translateY(-15%);
    -ms-transform: translateY(-15%);
    transform: translateY(-15%);
  }
}

.nuestras-obras-page-class .nuestras-obras-banner .container {
  max-width: 1280px;
}

.nuestras-obras-page-class .nuestras-obras-banner .container .title {
  border-bottom: solid 4px #4297be;
  max-width: 250px;
}

.nuestras-obras-page-class .nuestras-obras-cards .container {
  max-width: 1280px;
}

.nuestras-obras-page-class .nuestras-obras-cards .container .obras-list .card-box {
  padding-right: 30px;
}

.footer {
  font-weight: 500;
}

.footer .social-links {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer .social-links li {
  margin-bottom: 15px;
  display: block;
}

.footer .social-links li a {
  display: inline-block;
  color: #224878;
  opacity: 1;
}

.footer .social-links li a i {
  width: 25px;
  line-height: 25px;
  text-align: center;
  background-color: #fff;
  border-radius: 50%;
  font-size: 1.2em;
}

.footer .logo-footer {
  margin-bottom: 15px;
  display: inline-block;
}

.footer .paragraph {
  opacity: 0.75;
  font-weight: 500;
}

.footer a {
  text-decoration: none;
  opacity: 0.75;
}

.footer a:hover {
  opacity: 1;
  color: #fff;
}

.footer .footer-title {
  font-size: 1.4em;
  color: #fff;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 15px;
}

.footer .button {
  background-color: #fff;
  color: #224878;
  opacity: 1;
}

.footer .button:hover {
  color: #224878;
}

.clientes-section {
  height: 100%;
}

.clientes-section .container {
  max-width: 1280px;
  position: relative;
}

.clientes-section .contacto-button {
  position: absolute;
  bottom: 50px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.clientes-section .contacto-button i {
  background-color: #224878;
  color: #fff;
  display: inline-block;
  width: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50px;
  font-size: 1.6em;
}

.clientes-section .contacto-button h3 {
  font-family: "Klavika";
  color: #224878;
  line-height: 50px;
  display: inline-block;
  margin-left: 15px;
}

.clientes-section .contacto-button:hover {
  text-decoration: none;
  transition: 0.25s;
}

.clientes-section .contacto-button:hover i {
  background-color: #193558;
}

.clientes-section .contacto-button:hover h3 {
  color: #193558;
}

.clientes-section .owl-carousel .item {
  height: 200px;
  background-color: #fff;
}

.clientes-section .owl-carousel .item img {
  width: auto;
  height: auto;
  max-height: 100%;
  max-width: 180px;
  margin: 0 auto;
  opacity: 1;
}

.clientes-section .owl-carousel .item img:hover {
  opacity: 1;
}

.clientes-section .nav-button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-25%);
  -ms-transform: translateY(-25%);
  transform: translateY(-25%);
  font-size: 4em;
  color: #A5A9AD;
  z-index: 20;
}

.clientes-section .nav-button.prev {
  left: 0px;
}

.clientes-section .nav-button.next {
  right: 0px;
}

.clientes-section .nav-button:hover {
  color: #224878;
  text-decoration: none;
}

.clientes-section .nav-button:focus {
  color: #224878;
  text-decoration: none;
}

.contacto {
  min-height: 650px;
}

.contacto .image-sidebar {
  background-position: bottom right;
  background-size: auto 100%;
}

.contacto .image-sidebar .content {
  position: absolute;
  bottom: 0;
  padding-top: 60px;
  padding-bottom: 60px;
}

.contacto .image-sidebar .content i {
  background-color: #224878;
  color: #fff;
  display: inline-block;
  width: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50px;
  font-size: 1.6em;
}

.contacto .image-sidebar .content h1 {
  font-weight: 500;
  vertical-align: middle;
  line-height: normal;
  display: inline-block;
  margin-left: 15px;
}

.contacto .form-sidebar {
  vertical-align: middle;
  padding: 80px;
  padding-bottom: 40px;
}

.contacto .form-sidebar .button-send i {
  color: rgba(255, 255, 255, 0.5);
}

.contacto .form-sidebar .button-send:hover i {
  color: white;
}

.contacto .form-sidebar .icon-button:hover label {
  outline: 0;
  border-color: #224878;
  z-index: 99;
  color: #224878;
}

.contacto .form-sidebar .icon-button:hover i {
  color: #224878;
}

.contacto .form-sidebar .icon-button:focus {
  outline: 0;
  border-color: #224878;
  z-index: 99;
}

.contacto .form-sidebar .icon-button:focus label {
  color: #224878;
}

.contacto .form-sidebar .icon-button:focus i {
  color: #224878;
}

.contacto .form-sidebar .icon-button label {
  color: #8C8C8C;
}

.contacto .form-sidebar .icon-button i {
  position: absolute;
  background-color: transparent;
  right: 20px;
  top: 0;
  line-height: 52px;
  font-size: 1.4em;
}

.contacto .form-sidebar .contact-info .info {
  margin-right: 60px;
}

.contacto .form-sidebar .contact-info .info i {
  background-color: #224878;
  color: #fff;
  display: inline-block;
  width: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50px;
  font-size: 1.6em;
}

.contacto .form-sidebar .contact-info .info h4 {
  font-family: "Klavika";
  vertical-align: middle;
  line-height: normal;
  max-width: 265px;
  display: inline-block;
  margin-left: 15px;
}

.section-obras-home-box {
  height: 100%;
  height: 100vh;
  overflow: auto;
  z-index: 1000;
  position: fixed;
  padding-top: 66px;
  left: 100%;
  -webkit-transition: left 0.5s;
  -o-transition: left 0.5s;
  transition: left 0.5s;
}

.js-main-page-container {
  -webkit-transition: left 0.5s;
  -o-transition: left 0.5s;
  transition: left 0.5s;
  left: 0;
}

.js-home-show-projects .section-obras-home-box {
  left: 0;
}

.js-home-show-projects .js-main-page-container {
  left: -100%;
}

.scroll-loader {
  background-color: #EDEDED;
  border: solid 1px #EDEDED;
}

.scroll-loader img {
  margin-right: 10px;
  mix-blend-mode: multiply;
}

.scroll-loader h4 {
  margin-top: 5px;
}

.obra-card {
  list-style: none;
  margin-bottom: 30px;
}

.obra-card .obra-link .image {
  position: relative;
  padding: 15px;
  min-height: 300px;
}

.obra-card .obra-link .image .inside-box {
  visibility: hidden;
}

.obra-card .obra-link .info {
  padding-top: 20px;
}

.obra-card .obra-link .info .title {
  margin-bottom: 10px;
  font-family: "Klavika";
}

.obra-card .obra-link .info .subtitle {
  line-height: 20px;
}

.obra-card .obra-link:hover {
  text-decoration: none;
}

.obra-card .obra-link:hover .image .inside-box {
  visibility: visible;
  position: relative;
  min-height: 270px;
  width: 100%;
  display: block;
  border: solid 1px #fff;
}

.obra-card .obra-link:hover .info .title {
  color: #224878;
}

.obra-card .obra-link:focus {
  text-decoration: none;
}

.modal-obra .modal-dialog {
  width: 90%;
  max-width: 800px;
}

.modal-obra .modal-dialog .modal-content .main-slider .slider-image {
  height: 400px;
  margin-bottom: 0;
}

.modal-obra .modal-dialog .modal-content .main-slider .arrow-button {
  background-color: rgba(34, 72, 120, 0.8);
  width: 70px;
  line-height: 70px;
  position: absolute;
  z-index: 999;
  color: #fff;
  font-size: 1.6em;
}

.modal-obra .modal-dialog .modal-content .main-slider .arrow-button.prev {
  left: 0;
}

.modal-obra .modal-dialog .modal-content .main-slider .arrow-button.next {
  right: 0;
}

.modal-obra .modal-dialog .modal-content .main-slider .arrow-button:hover, .modal-obra .modal-dialog .modal-content .main-slider .arrow-button:focus {
  text-decoration: none;
  background-color: rgba(57, 120, 199, 0.8);
}

.modal-obra .modal-dialog .modal-content .main-slider .slider-pager {
  display: inline-block;
  position: absolute;
  z-index: 999;
  bottom: 20px;
  left: 0;
  width: 100%;
}

.modal-obra .modal-dialog .modal-content .main-slider .slider-pager span {
  display: none !important;
}

.modal-obra .modal-dialog .modal-content .main-slider .slider-pager .item-slider {
  display: inline-block;
  color: #A5A9AD;
  margin-right: 6px;
  margin-left: 6px;
  font-size: 13px;
  transition: 0.25s;
  -transition: 0,25s;
}

.modal-obra .modal-dialog .modal-content .main-slider .slider-pager .item-slider.cycle-pager-active {
  color: #224878;
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
}

.modal-obra .modal-dialog .modal-content .main-slider .slider-pager .item-slider:hover {
  cursor: pointer;
  color: #2B2B2B;
}

.modal-obra .modal-dialog .modal-content .content-box {
  padding: 25px 40px;
}

.modal-obra .modal-dialog .modal-content .content-box .title-section {
  margin-bottom: 20px;
}

.modal-obra .modal-dialog .modal-content .content-box .title-section .title {
  margin-bottom: 10px;
}

.modal-obra .modal-dialog .modal-content .content-box .title-section .subtitle {
  margin-bottom: 0;
}

.modal-obra .modal-dialog .modal-content .content-box .info-box .content-block {
  padding-right: 20px;
  border-left: solid 1px rgba(165, 169, 173, 0.5);
}

.modal-obra .modal-dialog .modal-content .content-box .info-box .content-block .subtitle {
  margin-bottom: 10px;
}

.modal-obra .modal-dialog .modal-content .content-box .info-box .content-block .info span {
  display: inline-block;
}

.modal-obra .modal-dialog .modal-content .content-box .info-box .content-block:first-child {
  border-left: 0;
}

@media all and (max-width: 992px) {
  .table-box-responsive {
    display: block;
  }
  .table-box-responsive .table-cell {
    display: block;
  }
  .table-box-responsive .table-cell.content {
    display: inline-block;
  }
  header.main-header {
    height: 66px;
  }
  ul.menu {
    padding: 0;
    margin: 0;
    list-style: none;
    display: inline-block;
  }
  ul.menu li a {
    text-decoration: none;
    font-size: 1.4em;
  }
  ul.menu.menu-horizontal li {
    display: inline-block;
    float: left;
  }
  ul.menu.menu-horizontal li a {
    display: inline-block;
    padding: 22px 15px;
    transition: all 0.25s;
    color: #8C8C8C;
    font-family: "Klavika";
    font-weight: 500;
    font-size: 1.6em;
  }
  ul.menu.menu-horizontal li a:hover {
    color: #224878;
    background-color: #EDEDED;
    box-shadow: inset 0px -4px 0px #224878;
  }
  ul.menu.menu-horizontal li a.menu-cta {
    background-color: #224878;
    color: #fff;
    padding: 22px 35px;
    border-radius: 0;
  }
  ul.menu.menu-horizontal li a.menu-cta i {
    margin-right: 10px;
    -webkit-transform: scale(1.4);
    -ms-transform: scale(1.4);
    transform: scale(1.4);
  }
  ul.menu.menu-horizontal li a.menu-cta:hover {
    color: #fff !important;
    background-color: #2B2B2B;
    box-shadow: 0px 0px 0px;
  }
  ul.menu.menu-horizontal li a.menu-search-btn {
    cursor: text;
  }
  ul.menu.menu-horizontal li a.menu-search-btn i {
    color: #4C433B;
    margin-right: 15px;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }
  ul.menu.menu-horizontal li a.menu-search-btn:hover {
    margin-right: 20px;
  }
  ul.menu.menu-horizontal li a.menu-search-btn:hover i {
    margin-right: 20px;
  }
  ul.menu.menu-horizontal .submenu-box {
    position: relative;
  }
  ul.menu.menu-horizontal .submenu-box li a {
    border-width: 700;
  }
  ul.menu.menu-horizontal .submenu-box .submenu {
    position: absolute;
    background-color: #fff;
    padding: 0;
    right: -10px;
    top: 80px;
    border-radius: 2px;
    width: 180px;
    display: none;
  }
  ul.menu.menu-horizontal .submenu-box .submenu li {
    display: block;
    width: 100%;
    position: relative;
  }
  ul.menu.menu-horizontal .submenu-box .submenu li a {
    display: block;
    width: 100%;
    color: #8C8C8C;
    padding: 10px 15px;
    text-align: right;
  }
  ul.menu.menu-horizontal .submenu-box .submenu li a i {
    width: 20px;
    text-align: right;
    opacity: 0.25;
    transition: all 0.25s;
  }
  ul.menu.menu-horizontal .submenu-box .submenu li a .icon {
    margin-left: 5px;
    -webkit-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
  }
  ul.menu.menu-horizontal .submenu-box .submenu li a:hover .icon {
    margin-left: 10px;
  }
  ul.menu.menu-horizontal .submenu-box .submenu li a:hover i {
    width: 30px;
    color: #224878;
    opacity: 1;
  }
  ul.menu.menu-horizontal .submenu-box .submenu .tri {
    position: absolute;
    top: -15px;
    right: 25px;
  }
  ul.menu.menu-horizontal .submenu-box .submenu .tri i {
    color: #fff;
    font-size: 2.4em;
  }
  ul.menu.menu-horizontal .lang-btn a {
    border-left: solid 1px rgba(237, 237, 237, 0.5);
    color: #224878;
  }
  ul.menu.menu-horizontal .counter {
    background-color: #224878;
    color: #fff;
    font-size: 10px;
    padding: 0px 4px;
    line-height: 20px;
    border-radius: 2px;
    display: inline-block;
    text-align: center;
    font-style: normal;
    position: absolute;
    top: 15px;
    right: 5px;
  }
  ul.menu.menu-horizontal .notifications-btn .button-user-action {
    background-color: transparent;
    border-radius: 0;
    border-left: solid 1px rgba(237, 237, 237, 0.5);
    cursor: pointer;
  }
  ul.menu.menu-horizontal .notifications-btn .button-user-action .icon {
    -webkit-transform: scale(1.4);
    -ms-transform: scale(1.4);
    transform: scale(1.4);
  }
  ul.menu.menu-horizontal .messages-btn a {
    border-left: solid 1px rgba(237, 237, 237, 0.5);
    padding-left: 20px;
    padding-right: 20px;
  }
  ul.menu.menu-horizontal .messages-btn a .icon {
    -webkit-transform: scale(1.4);
    -ms-transform: scale(1.4);
    transform: scale(1.4);
  }
  ul.menu.menu-horizontal .messages-btn a:hover {
    background-color: rgba(237, 237, 237, 0.5);
  }
  ul.menu.menu-horizontal .perfil-menu-box a {
    position: relative;
    padding-right: 60px;
  }
  ul.menu.menu-horizontal .perfil-menu-box .perfil {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 17px;
    background-color: #A5A9AD;
    background-image: url("/assets/online/img/profile.jpg");
  }
  ul.menu.menu-horizontal.menu-1 {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;
  }
  ul.menu.menu-horizontal.menu-2 {
    position: absolute;
    right: 0;
    top: 0;
  }
  ul.menu.menu-busqueda-type {
    margin-top: 7px;
  }
  ul.menu.menu-busqueda-type li a {
    padding: 6px 10px;
  }
  ul.menu.menu-busqueda-type li a:hover {
    color: #224878;
  }
  ul.menu.menu-busqueda-type li.active a {
    background-color: #224878;
    color: #fff;
    border-radius: 2px;
  }
  ul.menu.menu-busqueda-type li.active a:hover {
    opacity: 0.9;
    color: #fff;
  }
  ul.menu.menu-vertical {
    width: 100%;
    margin-bottom: 15px;
    margin: 0;
  }
  ul.menu.menu-vertical li a {
    padding: 15px 0px;
    display: inline-block;
    color: #8C8C8C;
    width: 100%;
    border-bottom: solid 1px #EDEDED;
  }
  ul.menu.menu-vertical li a i {
    color: #224878;
    margin-right: 10px;
  }
  body {
    padding-top: 65px;
  }
  footer.main-footer .footer-content.part1 {
    padding-left: 50px;
  }
  footer.main-footer .footer-content .logo img {
    margin-right: 0;
    max-width: 70px;
  }
  footer.main-footer .footer-content .text-info {
    margin-left: 20px;
  }
  footer.main-footer .footer-content.part2 {
    padding-top: 0;
    border-left: 0;
  }
  footer.main-footer .footer-content.part2 .redes-sociales {
    float: right;
  }
  footer.main-footer .footer-content.part2 .signature {
    float: left;
  }
  .modal-obra .modal-dialog .modal-content .content-box {
    padding: 20px 30px;
  }
  .modal-obra .modal-dialog .modal-content .content-box .info-box .content-block {
    border-right: 0;
    padding-left: 0;
  }
}

@media all and (max-width: 768px) {
  body {
    padding-top: 60px;
    font-size: 8px;
    cursor: pointer;
  }
  .cta-home-box .photo {
    height: 250px;
  }
  .cta-home-box .cta-home-content {
    padding-left: 50px;
  }
  .users-post-card-box {
    min-height: auto;
    padding: 15px;
  }
  .table-box-responsive-xs {
    display: block;
  }
  .table-box-responsive-xs .table-cell {
    display: block;
    float: inherit;
  }
  ul.menu {
    padding: 0;
    margin: 0;
    list-style: none;
    display: inline-block;
  }
  ul.menu li a {
    text-decoration: none;
    font-size: 1.4em;
  }
  ul.menu.menu-horizontal li {
    display: inline-block;
    float: left;
  }
  ul.menu.menu-horizontal li a {
    display: inline-block;
    padding: 20px;
    transition: all 0.25s;
    color: #8C8C8C;
    font-family: "Klavika";
    font-weight: 500;
    font-size: 1.8em;
  }
  ul.menu.menu-horizontal li a:hover {
    color: #224878;
    background-color: #EDEDED;
    box-shadow: inset 0px -4px 0px #224878;
  }
  ul.menu.menu-horizontal li a.menu-cta {
    background-color: #224878;
    color: #fff;
    padding: 24px 35px;
    border-radius: 0;
  }
  ul.menu.menu-horizontal li a.menu-cta i {
    margin-right: 10px;
    -webkit-transform: scale(1.4);
    -ms-transform: scale(1.4);
    transform: scale(1.4);
  }
  ul.menu.menu-horizontal li a.menu-cta:hover {
    color: #fff !important;
    background-color: #2B2B2B;
    box-shadow: 0px 0px 0px;
  }
  ul.menu.menu-horizontal li a.menu-search-btn {
    cursor: text;
  }
  ul.menu.menu-horizontal li a.menu-search-btn i {
    color: #4C433B;
    margin-right: 15px;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }
  ul.menu.menu-horizontal li a.menu-search-btn:hover {
    margin-right: 20px;
  }
  ul.menu.menu-horizontal li a.menu-search-btn:hover i {
    margin-right: 20px;
  }
  ul.menu.menu-horizontal .submenu-box {
    position: relative;
  }
  ul.menu.menu-horizontal .submenu-box li a {
    border-width: 700;
  }
  ul.menu.menu-horizontal .submenu-box .submenu {
    position: absolute;
    background-color: #fff;
    padding: 0;
    right: -10px;
    top: 80px;
    border-radius: 2px;
    width: 180px;
    display: none;
  }
  ul.menu.menu-horizontal .submenu-box .submenu li {
    display: block;
    width: 100%;
    position: relative;
  }
  ul.menu.menu-horizontal .submenu-box .submenu li a {
    display: block;
    width: 100%;
    color: #8C8C8C;
    padding: 10px 15px;
    text-align: right;
  }
  ul.menu.menu-horizontal .submenu-box .submenu li a i {
    width: 20px;
    text-align: right;
    opacity: 0.25;
    transition: all 0.25s;
  }
  ul.menu.menu-horizontal .submenu-box .submenu li a .icon {
    margin-left: 5px;
    -webkit-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
  }
  ul.menu.menu-horizontal .submenu-box .submenu li a:hover .icon {
    margin-left: 10px;
  }
  ul.menu.menu-horizontal .submenu-box .submenu li a:hover i {
    width: 30px;
    color: #224878;
    opacity: 1;
  }
  ul.menu.menu-horizontal .submenu-box .submenu .tri {
    position: absolute;
    top: -15px;
    right: 25px;
  }
  ul.menu.menu-horizontal .submenu-box .submenu .tri i {
    color: #fff;
    font-size: 2.4em;
  }
  ul.menu.menu-horizontal .lang-btn a {
    border-left: solid 1px rgba(237, 237, 237, 0.5);
    color: #224878;
  }
  ul.menu.menu-horizontal .counter {
    background-color: #224878;
    color: #fff;
    font-size: 10px;
    padding: 0px 4px;
    line-height: 20px;
    border-radius: 2px;
    display: inline-block;
    text-align: center;
    font-style: normal;
    position: absolute;
    top: 15px;
    right: 5px;
  }
  ul.menu.menu-horizontal .notifications-btn .button-user-action {
    background-color: transparent;
    border-radius: 0;
    border-left: solid 1px rgba(237, 237, 237, 0.5);
    cursor: pointer;
  }
  ul.menu.menu-horizontal .notifications-btn .button-user-action .icon {
    -webkit-transform: scale(1.4);
    -ms-transform: scale(1.4);
    transform: scale(1.4);
  }
  ul.menu.menu-horizontal .messages-btn a {
    border-left: solid 1px rgba(237, 237, 237, 0.5);
    padding-left: 20px;
    padding-right: 20px;
  }
  ul.menu.menu-horizontal .messages-btn a .icon {
    -webkit-transform: scale(1.4);
    -ms-transform: scale(1.4);
    transform: scale(1.4);
  }
  ul.menu.menu-horizontal .messages-btn a:hover {
    background-color: rgba(237, 237, 237, 0.5);
  }
  ul.menu.menu-horizontal .perfil-menu-box a {
    position: relative;
    padding-right: 60px;
  }
  ul.menu.menu-horizontal .perfil-menu-box .perfil {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 17px;
    background-color: #A5A9AD;
    background-image: url("/assets/online/img/profile.jpg");
  }
  ul.menu.menu-horizontal.menu-1 {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;
  }
  ul.menu.menu-horizontal.menu-2 {
    position: absolute;
    right: 0;
    top: 0;
  }
  ul.menu.menu-busqueda-type {
    margin-top: 7px;
  }
  ul.menu.menu-busqueda-type li a {
    padding: 6px 10px;
  }
  ul.menu.menu-busqueda-type li a:hover {
    color: #224878;
  }
  ul.menu.menu-busqueda-type li.active a {
    background-color: #224878;
    color: #fff;
    border-radius: 2px;
  }
  ul.menu.menu-busqueda-type li.active a:hover {
    opacity: 0.9;
    color: #fff;
  }
  ul.menu.menu-vertical {
    width: 100%;
    margin-bottom: 15px;
    margin: 0;
  }
  ul.menu.menu-vertical li a {
    padding: 15px 0px;
    display: inline-block;
    color: #8C8C8C;
    width: 100%;
    border-bottom: solid 1px #EDEDED;
  }
  ul.menu.menu-vertical li a i {
    color: #224878;
    margin-right: 10px;
  }
  .modal-obra .modal-dialog .modal-content .main-slider .slider-image {
    height: 300px;
  }
  .modal-obra .modal-dialog .modal-content .content-box {
    padding: 20px 30px;
  }
  .modal-obra .modal-dialog .modal-content .content-box .info-box .content-block {
    border-right: 0;
    padding-left: 0;
  }
  .obra-card {
    margin-bottom: 20px;
  }
  .obra-card .obra-link .image {
    position: relative;
    padding: 15px;
    min-height: 200px;
  }
  .contacto .form-sidebar {
    vertical-align: middle;
    padding: 40px;
  }
  .contacto .form-sidebar .contact-info .info {
    margin-right: 40px;
  }
  footer.main-footer .section-short {
    padding-top: 10px;
    padding-bottom: 0;
  }
  footer.main-footer p {
    margin: 0;
  }
  footer.main-footer .link-janaq {
    display: block;
  }
  footer.main-footer .footer-content.part2 .redes-sociales {
    padding-left: 26px;
    float: right;
    display: block;
  }
  footer.main-footer .footer-content.part2 .signature {
    float: left;
    display: block;
  }
  .null-padding-side-xs {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .section {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .menu-mobil-box {
    font-size: 10px;
  }
  .custom-modal.user-dialogs .modal-dialog {
    padding: 25px;
    max-height: 525px;
    overflow: auto;
    top: 20px;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .home-page-class .nosotros-section .banner-content .title {
    margin-top: 40px;
  }
  .home-page-class .obras-section .banner-content .title {
    margin-top: 0;
  }
}

@media all and (max-width: 480px) {
  footer.main-footer .footer-content.part1 {
    padding-left: 35px;
  }
  footer.main-footer .footer-content.part1 .text-info {
    display: block;
    padding-left: 0;
    margin-left: 0;
  }
  footer.main-footer .footer-content.part2 .text-info {
    padding-left: 8px;
    margin-left: 0;
  }
  footer.main-footer .footer-content.part2 .redes-sociales {
    padding-left: 15px;
  }
  .modal-obra .modal-dialog .modal-content .main-slider .arrow-button {
    width: 40px;
    line-height: 40px;
  }
  .modal-obra .modal-dialog .modal-content .main-slider .slider-image {
    height: 200px;
  }
  .modal-obra .modal-dialog .modal-content .content-box {
    padding: 15px 20px;
  }
  .modal-obra .modal-dialog .modal-content .content-box .info-box .content-block {
    border-right: 0;
    padding-left: 0;
  }
  .nosotros-section .down-anchor-link {
    display: none;
  }
  .contacto .form-sidebar {
    vertical-align: middle;
    padding: 30px;
  }
  .contacto .form-sidebar .contact-info .info {
    margin-right: 20px;
  }
  .contacto .form-sidebar .contact-info .info i {
    width: 40px;
    line-height: 40px;
    display: block;
    margin-bottom: 10px;
  }
  .contacto .form-sidebar .contact-info .info h4 {
    margin-left: 0;
  }
}

#fp-nav ul li a span {
  background-color: transparent;
  width: 10px !important;
  height: 10px !important;
  margin: 0 !important;
  border: solid 1px #2B2B2B;
}

#fp-nav ul li a.active span, #fp-nav ul li a:hover span, #fp-nav ul li a.active:hover span {
  background-color: #2B2B2B;
  margin: 0;
}

#fp-nav ul li .fp-tooltip {
  background-color: #2B2B2B;
  padding: 10px;
}
