.contacto{
     .form-sidebar{
        vertical-align: middle;
        padding: 30px;
        .contact-info{
            .info{
                margin-right: 20px;
                i{
                    width: 40px;
                    line-height: 40px;
                    display: block;
                    margin-bottom: 10px;
                }
                h4{
                    margin-left: 0;
                }
            }
        }
     }
}
