.obra-card{
    list-style: none;
    margin-bottom: 30px;
    .obra-link{
        .image{
            position: relative;
            padding: 15px;
            min-height: 300px;
            .inside-box{
                visibility: hidden;
            }
        }
        .info{
            padding-top: 20px;
            .title{
                margin-bottom: 10px;
                font-family: $font-family-secondary;
            }
            .subtitle{
                line-height: 20px;
            }
        }
        &:hover{
            text-decoration: none;
            .image{
                .inside-box{
                    visibility: visible;
                    position: relative;
                    min-height: 270px;
                    width: 100%;
                    display: block;
                    border: solid 1px $color-white;
                }
            }
            .info{
                .title{
                    color: $color-primary;
                }
            }

        }
        &:focus{
          text-decoration: none;
        }
    }
}
