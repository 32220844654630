@media all and (max-width: 992px){
.table-box-responsive{
     display: block;
     .table-cell{
        display: block;
        &.content{
        display: inline-block;
     }
    }
  }

    // Base
    @import "992px/base/header";
    @import "992px/base/menu";
    @import "992px/base/general";
    @import "992px/base/footer";

    // Modals
    @import "992px/modals/modal-obra";

}
