.obra-card{
    margin-bottom: 20px;
    .obra-link{
        .image{
            position: relative;
            padding: 15px;
            min-height: 200px;
        }
    }
}
