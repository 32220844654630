.section-obras-home-box{
  height: 100%;
  height: 100vh;
  overflow: auto;
  z-index: 1000;
  position: fixed;
  padding-top: 66px;
  left: 100%;
  -webkit-transition: left 0.5s;
  -o-transition: left 0.5s;
  transition: left 0.5s;
}

.js-main-page-container{
  -webkit-transition: left 0.5s;
  -o-transition: left 0.5s;
  transition: left 0.5s;
  left: 0;
}

.js-home-show-projects{
  .section-obras-home-box{
    left: 0;
  }

  .js-main-page-container{
    left: -100%;
  }
}

.scroll-loader{
  background-color: $color-gray-secondary;
  border: solid 1px $color-gray-secondary;

  img{
    margin-right: 10px;
    mix-blend-mode: multiply;
  }

  h4{
    margin-top: 5px;
  }
}
