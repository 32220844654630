.nosotros-section{
    height: 100vh;
    min-height: 650px;
    .banner-content{
        .title{
            margin-top: 280px;
            margin-bottom: 40px;
        }
    }
}
