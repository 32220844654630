.home-page-class{

  body{
    padding-top: 0;
  }

    // Sections
    @import "pages/sections/home/nosotros";
    @import "pages/sections/home/obras";

  .select2-container--default .select2-search--dropdown{
    display: none;
  }

}

.down-anchor-link{
  position: absolute;
  display: inline-block;
  bottom: 50px;
  font-size: 3.6em;
  left: 50%;
  margin-left: -20px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  background-color: $color-white;
  color: $color-primary;
  // -webkit-animation: floatMove 1s infinite linear;
  // -o-animation: floatMove 1s infinite linear;
  // animation: floatMove 1s infinite linear;

  &:hover, &:focus{
    color: $color-primary;
    background-color: $color-white;
    text-decoration: none;
  }
}

@keyframes floatMove {
  0%{
    -webkit-transform: translateY(-15%);
    -ms-transform: translateY(-15%);
    transform: translateY(-15%);
  }

  50%{
    -webkit-transform: translateY(15%);
    -ms-transform: translateY(15%);
    transform: translateY(15%);
  }

  100%{
    -webkit-transform: translateY(-15%);
    -ms-transform: translateY(-15%);
    transform: translateY(-15%);
  }

}
