.contacto{
     min-height: 650px;
     .image-sidebar{
       background-position: bottom right;
       background-size: auto 100%;

         .content{
             position: absolute;
             bottom: 0;
             padding-top: 60px;
             padding-bottom: 60px;
             i{
                 background-color: $color-primary;
                 color: $color-white;
                 display: inline-block;
                 width: 50px;
                 line-height: 50px;
                 text-align: center;
                 border-radius: 50px;
                 font-size: 1.6em;
             }
             h1{
                 font-weight: $fw-semi-bold;
                 vertical-align: middle;
                 line-height: normal;
                 display: inline-block;
                 margin-left: 15px;
             }
        }
     }
     .form-sidebar{
        vertical-align: middle;
        padding: 80px;
        padding-bottom: 40px;
        
        .button-send{
            i{
                color: rgba($color-white, 0.5);
            }
            &:hover{
                i{
                    color: rgba($color-white, 1);
                }
            };
        }

        .icon-button{
            &:hover{
              label{
                outline: 0;
                border-color: $color-primary;
                z-index: 99;
                color: $color-primary;
              }
              i{
                  color: $color-primary;
              }
            }
            &:focus{
              outline: 0;
              border-color: $color-primary;
              z-index: 99;
              label{
                  color: $color-primary;
              }
              i{
                  color: $color-primary;
              }
            }
            label{
              color: $color-text-secondary;
            }
          i{
            position: absolute;
            background-color: transparent;
            right: 20px;
            top: 0;
            line-height: 52px;
            font-size: 1.4em;
          }
         }

        .contact-info{
            .info{
                margin-right: 60px;
                i{
                    background-color: $color-primary;
                    color: $color-white;
                    display: inline-block;
                    width: 50px;
                    line-height: 50px;
                    text-align: center;
                    border-radius: 50px;
                    font-size: 1.6em;
                }
                h4{
                    font-family: $font-family-secondary;
                    vertical-align: middle;
                    line-height: normal;
                    max-width: 265px;
                    display: inline-block;
                    margin-left: 15px;
                }

            }
        }
}
}
