//////////////////////////////////////////

// Footer
footer.main-footer{

  .footer-content{

    &.part1{
      padding-left: 50px;
    }

    .logo{
        img{
            margin-right: 0;
            max-width: 70px;
        }
    }

    .text-info{
        margin-left: 20px;
    }

    &.part2{
        padding-top: 0;
        border-left: 0;
        .redes-sociales{
            float: right;
        }
        .signature{
            float: left;
        }
    }

  }

}
