.obras-section{
  height: 100vh;
  min-height: 650px;
  .overlay{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      z-index: 0;
      background-color: rgba($color-primary,0.7); /*dim the background*/
    }
  .banner-content{
      .title{
          margin-top: 225px;
          margin-bottom: 40px;
      }
  }
}
