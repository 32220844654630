.modal-obra{
  .modal-dialog{
    .modal-content{
      .main-slider{
        .slider-image{
          height: 300px;
        }
      }
      .content-box{
        padding: 20px 30px;
        .info-box{
          .content-block{
            border-right: 0;
            padding-left: 0;
          }
        }
      }
    }
  }
}
