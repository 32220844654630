@media all and (max-width: 768px){

body{
  padding-top: 60px;
  font-size: 8px;
  cursor: pointer;
}

// Footer

.cta-home-box{
  .photo{
    height: 250px
  }

  .cta-home-content{
    padding-left: 50px;
  }
}

.users-post-card-box{
  min-height: auto;
  padding: 15px;
}

// Base
@import "768px/base/ds-tables";
@import "768px/base/menu";

// Modals
@import "768px/modals/modal-obra";

// Cards
@import "768px/cards/obra-card";

// Sections
@import "768px/pages/sections/contacto";

@import "768px/header";
@import "768px/footer";
@import "768px/helpers";
@import "768px/menu-mobil";
@import "768px/custom-modal";


.home-page-class .nosotros-section .banner-content .title{
  margin-top: 40px;
}

.home-page-class .obras-section .banner-content .title{
  margin-top: 0;
}

}
