//////////////////////////////////////////

// Footer
footer.main-footer{

  .footer-content{
    &.part1{
      padding-left: 35px;
      .text-info{
        display: block;
        padding-left: 0;
        margin-left: 0;
      }
    }
    &.part2{
      .text-info{
        padding-left: 8px;
        margin-left: 0;
      }
      .redes-sociales{
          padding-left: 15px;
      }
    }

  }

}
