.modal-obra{
  .modal-dialog{
    width: 90%;
    max-width: 800px;
    .modal-content{
      .main-slider{
        .slider-image{
          height: 400px;
          margin-bottom: 0;
        }

        .arrow-button{
          background-color: rgba($color-primary, 0.8);
          width: 70px;
          line-height: 70px;
          position: absolute;
          z-index: 999;
          color: $color-white;
          font-size: 1.6em;
         &.prev{
          left: 0;
         }
         &.next{
          right: 0;
         }
         &:hover, &:focus{
           text-decoration: none;
           background-color: lighten(rgba($color-primary,0.8), 20%);
         }
        }

        .slider-pager{
          display: inline-block;
          position: absolute;
          z-index: 999;
          bottom: 20px;
          left: 0;
          width: 100%;
          span{
            display: none !important;
          }
          .item-slider{
            display: inline-block;
            color: $color-gray-primary;
            margin-right: 6px;
            margin-left: 6px;
            font-size: 13px;
            transition: 0.25s;
            -transition: 0,25s;
            &.cycle-pager-active{
              color: $color-primary;
              -webkit-transform: scale(1.3);
              -ms-transform: scale(1.3);
              transform: scale(1.3);
            }
            &:hover{
              cursor: pointer;
              color: $color-secondary;
            }
          }
        }
      }
      .content-box{
        padding: 25px 40px;
        .title-section{
          margin-bottom: 20px;
          .title{
            margin-bottom: 10px;
          }
          .subtitle{
            margin-bottom: 0;
          }
        }
        .info-box{
          .content-block{
            padding-right: 20px;
            border-left: solid 1px rgba($color-gray-primary, 0.5);
            .subtitle{
              margin-bottom: 10px;
            }
            .info{
              span{
                display: inline-block;
              }
            }
            &:first-child{
              border-left: 0;
            }

            // &:not([style*="display: none"]):last-child{
            //   background-color: blue;
            // }

          }
        }
      }
    }
  }
}
