.dialog-alert-modal{

  .modal-dialog{
    background-color: transparent
  }

 .modal-content{
   background-color: transparent;

   .header-dialog-modal{
     //padding: 15px 20px;
     h5{
       margin-bottom: 0;
       color: $color-white;
       font-weight: bold;
       font-family: $font-family-secondary;
     }
   }

   .content-dialog-modal{
     //padding: 10px 20px;
     p{
       margin-bottom: 0;
       color: #8C8C8C;
     }
   }

   .footer-dialog-modal{
     border-top: solid 1px $color-gray-secondary;
     padding-top: 10px;
     padding-bottom: 2px;
     .button{
       margin-left: 8px;
     }
   }

 }

}
